<template>
  <div>
    <b-navbar
      type="dark"
      variant="dark"
      class="mb-2"
    >
      <b-navbar-brand to="/">
        <img
          height="32"
          src="./assets/logo.gif"
          alt="Ernst Klett Verlag GmbH"
        >
        Docs
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" />

      <b-collapse
        id="nav-collapse"
        is-nav
      >
        <b-navbar-nav class="ml-auto">
          <b-nav-item @click="showDoc(null, null)">
            Overview
          </b-nav-item>
          <b-nav-item-dropdown
            v-for="category in categoryList"
            :key="category.name"
            :text="category.name"
            right
          >
            <b-dropdown-group
              v-for="doc in category.docList"
              :key="doc.name"
              :header="doc.name"
              class="versionList"
            >
              <b-dropdown-item-button
                v-for="(version, index) in doc.versionList"
                :key="version.name"
                @click="showDoc(doc, version)"
              >
                {{ version.name }}
                <span v-if="index === 0">
                  (latest)
                </span>
              </b-dropdown-item-button>
            </b-dropdown-group>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-container v-show="!showIframe">
      <h1>
        Overview
      </h1>
      <ul>
        <li
          v-for="category in categoryList"
          :key="category.name"
        >
          {{ category.name }}
          <ul>
            <li
              v-for="doc in category.docList"
              :key="doc.name"
            >
              {{ doc.name }}
              <ul class="versionList">
                <li
                  v-for="(version, index) in doc.versionList"
                  :key="version.name"
                >
                  <b-link @click="showDoc(doc, version)">
                    {{ version.name }}
                    <span v-if="index === 0">
                      (latest)
                    </span>
                  </b-link>
                  <b-link
                    :href="version.path"
                    class="small text-muted"
                    target="_blank"
                  >
                    <font-awesome-icon :icon="['far', 'external-link']" />
                  </b-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </b-container>
    <b-container v-show="showIframe">
      <h1>
        API:
        <span ref="viewHeadline" />
        <b-link
          ref="viewExternalLink"
          class="text-muted ml-1"
          target="_blank"
        >
          <font-awesome-icon :icon="['far', 'external-link']" />
        </b-link>
      </h1>
      <iframe
        ref="viewIframe"
        title="External documentation"
      />
    </b-container>
  </div>
</template>

<script>
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import axios from 'axios';
import findVersions from 'find-versions';
import findLatestVersions from 'latest-semver';
import semver from 'semver';

export default {
  name: 'App',

  props: {
    apiBaseUrl: {
      type: String,
      default: process.env.VUE_APP_API_BASE_URL,
    },
  },
  data() {
    return {
      categoryList: [],
      showIframe: false,
      docs: {},
    };
  },
  async beforeMount() {
    await this.getDocs();
    // Transform input data
    Object.keys(this.docs).forEach((category) => {
      const docList = {};

      Object.keys(this.docs[category]).forEach((doc) => {
        const versionList = findVersions(doc, { loose: true });
        const version = versionList.length === 0 ? '-/-' : versionList.pop();
        const name = doc.substring(0, doc.length - (version.length > 0 ? version.length + 1 : 0));

        if (docList[name] === undefined) {
          docList[name] = {
            name,
            latest: null,
            versionList: [],
          };
        }

        docList[name].versionList.push({
          name: version,
          path: this.docs[category][doc].uri,
          modified: this.docs[category][doc].modification,
        });
      });

      this.categoryList.push({
        name: category,
        docList: Object.values(docList).map((doc) => {
          const latestVersion = findLatestVersions(doc.versionList.map((version) => version.name))
            || doc.versionList[0].name;
          return {
            name: doc.name,
            latest: doc.versionList.find((version) => version.name === latestVersion),
            versionList: doc.versionList.sort((a, b) => semver.rcompare(a.name, b.name)),
          };
        }).sort((a, b) => a.name.localeCompare(b.name)),
      });
    });
    this.categoryList.sort((a, b) => a.name.localeCompare(b.name));
  },
  methods: {
    async getDocs() {
      try {
        // Get Docs directory structure from Lua script
        const docsResponse = await axios.get(`${this.apiBaseUrl}/api/1.0/docs`);
        this.docs = docsResponse.data;
      } catch (error) {
        console.error(error);
      }
    },
    showDoc(doc, version) {
      if (version !== null && doc !== null) {
        this.showIframe = true;
        this.$refs.viewHeadline.innerHTML = `${doc.name} ${version.name}`;
        this.$refs.viewExternalLink.href = version.path;
        this.$refs.viewIframe.src = version.path;
      } else {
        this.showIframe = false;
      }
    },
  },
};
</script>

<style>
html, body, body > div {
  height: 100%;
}

div.container {
  height: 80%;
}

.dropdown-toggle:after {
  margin-left: .255em !important;
}

.versionList li:first-child a, .versionList ul li:first-child button {
  font-weight: bold;
}

iframe {
  width: 100%;
  height: 100%;
  border: 0;
  bottom: 0;
}
</style>
